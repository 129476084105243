import axios from "axios";

const API_CONFIG = process.env.REACT_APP_API_MODE ? process.env.REACT_APP_API_MODE === 'production' ? process.env.REACT_APP_API_PRODUCAO : process.env.REACT_APP_API_MODE === 'test' ? process.env.REACT_APP_API_TESTE : process.env.REACT_APP_API_LOCALHOST : process.env.REACT_APP_API_LOCALHOST;
const PATH_TOKEN = "/oauth/token";
const PATH_USUARIOS = "/usuarios"
const USUARIO_LOGADO = "usuario";
const ACCESS_TOKEN = "access_auth";
const REFRESH_TOKEN = "refresh_auth";
const customAxios = axios.create({
  baseURL: API_CONFIG,
});

class jwtService {
  authTokenRequest = null;

  init() {
    this.setInterceptors();
  }

  signInWithUsernameAndPassword = (email, password) => {
    this.setSession(null);
    return new Promise((resolve, reject) => {
      this.login(email, password)
        .then((response) => {
          if (response.data) {
            this.setTokens(response);
            this.getUserData().then((response) => {
              this.setSession(response);
              resolve(response.data);
            });
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  signInAdminWithUsernameAndPassword = (email, password) => {
    this.setSession(null);
    return new Promise((resolve, reject) => {
      this.login(email, password)
        .then((response) => {
          if (response.data) {
            this.setTokens(response);
            this.getUserData().then((response) => {
              this.setSession(response);
              resolve(response.data);
            });
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getUserData = async () => {
    return customAxios.get("/user-auth");
  };

  login = async (email, password) => {
    var reqData = `grant_type=password&username=${email}&password=${password}`;
    return axios.request({
      url: PATH_TOKEN,
      method: "post",
      baseURL: API_CONFIG,
      auth: {
        username: "webGtechEcfWeb", // This is the client_id
        password: "g2010tech", // This is the client_secret
      },
      data: reqData,
    });
  };

  refreshToken = async (error) => {
    var reqData = `grant_type=refresh_token&refresh_token=${this.getRefreshToken()}`;
    return axios.request({
      url: PATH_TOKEN,
      method: "post",
      baseURL: API_CONFIG,
      auth: {
        username: "webGtechEcfWeb", // This is the client_id
        password: "g2010tech", // This is the client_secret
      },
      data: reqData,
    });
  };

  setTokens = (response) => {
    if (response && response.data) {
      localStorage.setItem(
        ACCESS_TOKEN,
        JSON.stringify(response.data.access_token)
      );
      localStorage.setItem(
        REFRESH_TOKEN,
        JSON.stringify(response.data.refresh_token)
      );
    } else {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
    }
  };

  setSession = (response) => {
    if (response && response.data) {
      this.setUsuarioLogado(response.data);
    } else {
      localStorage.removeItem(USUARIO_LOGADO);
    }
  };

  logout = () => {
    this.setSession(null);
  };

  setEmpresaLogada = (usuario, empresa) => {
    return new Promise((resolve, reject) => {
      customAxios
        .put(`${PATH_USUARIOS}/${usuario.codigo}/set_empresa_logada`, {
          idEmpresa: empresa.codigo,
        })
        .then((response) => {
          localStorage.setItem(USUARIO_LOGADO, JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  setUsuarioLogado = (data) => {
    localStorage.setItem(USUARIO_LOGADO, JSON.stringify(data));
  }
  
  getUsuarioLogado = () => {
    return JSON.parse(localStorage.getItem(USUARIO_LOGADO));
  };

  getAccessToken = () => {
    return JSON.parse(localStorage.getItem(ACCESS_TOKEN));
  };

  getRefreshToken = () => {
    return JSON.parse(localStorage.getItem(REFRESH_TOKEN));
  };

  getUrlAvatar = (avatar) =>{
    if(avatar)
      return `${API_CONFIG}${avatar}?time=${new Date().getTime()}`
    return "";
  }

  getAuthToken = () => {
    if (!this.authTokenRequest) {
      this.authTokenRequest = this.refreshToken();
      this.authTokenRequest.then(
        this.resetAuthTokenRequest,
        this.resetAuthTokenRequest
      );
    }

    return this.authTokenRequest;
  };

  resetAuthTokenRequest = () => {
    this.authTokenRequest = null;
  };

  setInterceptors = () => {
    customAxios.interceptors.request.use(
      async (config) => {
        const userToken = await this.getAccessToken();
        const usuarioLogado = await this.getUsuarioLogado();
        config.headers.Authorization = `Bearer ${userToken}`;
        if(usuarioLogado && usuarioLogado.empresaLogada) {
          config.headers = { ...config.headers, ...{
            'empresa-id': usuarioLogado.empresaLogada.codigo
          }};
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    customAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401){
          if(!originalRequest.__isRetryRequest) {
            return this.getAuthToken()
              .then((response) => {
                this.setTokens(response);
                error.config.__isRetryRequest = true;
                return customAxios(originalRequest);
              })
              .catch((err) => {
                this.setSession(null);
                this.setTokens(null);
                window.location.reload();
                throw err;
              });
            }else{
              this.setSession(null);
              this.setTokens(null);
              window.location.reload();
            }
        }
        throw error;
      }
    );
  };

  getInterceptor() {
    return customAxios;
  }
}

const instance = new jwtService();

export default instance;
