export default {
    items: [
        {
            id: 'pages',
            title: 'Pages',
            type: 'group',
            icon: 'icon-pages',
            children: [
                {
                    id: 'menu-emissao',
                    title: 'VENDA',
                    type: 'collapse',
                    icon: 'fas fa-shopping-basket',
                    children: [
                        {
                            id: 'pdv',
                            title: 'PDV',
                            type: 'item',
                            url: '/pdv/novo',
                            classes: 'nav-item',
                            icon: 'fas fa-shopping-cart',
                        },
                        {
                            id: 'nfe',
                            title: 'NFE',
                            type: 'item',
                            url: '/nfe/novo',
                            classes: 'nav-item',
                            icon: 'fas fa-receipt',
                        },
                        {
                            id: 'gerenciar-auxiliar-venda',
                            title: 'GERENCIAR AUX VENDA',
                            type: 'item',
                            url: '/auxiliar-venda',
                            classes: 'nav-item',
                            icon: 'fas fa-file-contract'
                        },
                        {
                            id: 'gerenciar-nfce',
                            title: 'GERENCIAR NFC-E',
                            type: 'item',
                            url: '/nfce',
                            classes: 'nav-item',
                            icon: 'fas fa-file-contract'
                        },
                        {
                            id: 'gerenciar-nfe',
                            title: 'GERENCIAR NFE',
                            type: 'item',
                            url: '/nfe',
                            classes: 'nav-item',
                            icon: 'fas fa-file-contract'
                        },
                        {
                          id: 'nfa',
                          title: 'NFA-E',
                          type: 'item',
                          url: 'https://www.sefaz.rs.gov.br/nfa/nfe-nfa-mei.aspx',
                          classes: 'nav-item',
                          icon: 'fas fa-shopping-cart',
                          target: 'true',
                          external: 'true'
                        },
                        // {
                        //     id: 'notas-entrada',
                        //     title: 'NOTAS DE ENTRADA',
                        //     type: 'item',
                        //     url: '/nota_entrada',
                        //     classes: 'nav-item',
                        //     icon: 'fas fa-file-contract'
                        // },
                        // {
                        //     id: 'nfce-carga',
                        //     title: 'NFCe TESTE CARGA',
                        //     type: 'item',
                        //     url: '/carga',
                        //     classes: 'nav-item',
                        //     icon: 'fab fa-dev'
                        // },


                    ]
                },
                {
                    id: 'menu-level',
                    title: 'CADASTROS',
                    type: 'collapse',
                    icon: 'fas fa-file-alt',
                    children: [
                        {
                            id: 'produto_level',
                            title: 'PRODUTOS',
                            type: 'collapse',
                            icon: 'fas fa-box',
                            children: [

                                {
                                    id: 'produtos',
                                    title: 'PRODUTO',
                                    type: 'item',
                                    url: '/cadastros/produtos',
                                    classes: 'nav-item',
                                    icon: 'fas fa-box'
                                },
                                {
                                    id: 'marcas',
                                    title: 'MARCAS',
                                    type: 'item',
                                    url: '/cadastros/marcas',
                                    classes: 'nav-item',
                                    icon: 'fa fa-hashtag'
                                },
                                {
                                    id: 'departamentos',
                                    title: 'DEPARTAMENTOS',
                                    type: 'item',
                                    url: '/cadastros/departamentos',
                                    classes: 'nav-item',
                                    icon: 'fa fa-tag'
                                },
                                {
                                    id: 'subdepartamentos',
                                    title: 'SUBDEPARTAMENTOS',
                                    type: 'item',
                                    url: '/cadastros/subdepartamentos',
                                    classes: 'nav-item',
                                    icon: 'fa fa-tags'
                                },
                                {
                                    id: 'tabelaPreco',
                                    title: 'TABELA DE PREÇOS',
                                    type: 'item',
                                    url: '/cadastros/tabela_preco',
                                    classes: 'nav-item',
                                    icon: 'fas fa-dollar-sign'
                                },
                                {
                                    id: 'unidades_medida',
                                    title: 'UNIDADES DE MEDIDA',
                                    type: 'item',
                                    url: '/cadastros/unidades_medida',
                                    classes: 'nav-item',
                                    icon: 'fas fa-balance-scale'
                                },
                                {
                                    id: 'especieVolume',
                                    title: 'UNIDADES DE VOLUME',
                                    type: 'item',
                                    url: '/cadastros/unidades_volume',
                                    classes: 'nav-item',
                                    icon: 'fas fa-boxes'
                                },
                            ]
                        },
                        {
                            id: 'tributacao_level',
                            title: 'TRIBUTAÇÃO',
                            type: 'collapse',
                            icon: 'fas fa-vials',
                            children: [
                                {
                                    id: 'tributacaoPorNCM',
                                    title: 'TRIBUTAÇÃO POR NCM',
                                    type: 'item',
                                    url: '/cadastros/tributacao_por_ncm',
                                    classes: 'nav-item',
                                    icon: 'fas fa-vials'
                                },
                                {
                                    id: 'tributacaoPorProduto',
                                    title: 'TRIBUTAÇÃO POR PRODUTO',
                                    type: 'item',
                                    url: '/cadastros/tributacao_por_produto',
                                    classes: 'nav-item',
                                    icon: 'fas fa-vial'
                                },
                            ]
                        },
                        {
                            id: 'transportadora_level',
                            title: 'TRANSPORTADORAS',
                            type: 'collapse',
                            icon: 'fas fa-dolly',
                            children: [
                                {
                                    id: 'transportadoras',
                                    title: 'TRANSPORTADORAS',
                                    type: 'item',
                                    url: '/cadastros/transportadoras',
                                    classes: 'nav-item',
                                    icon: 'fas fa-dolly'
                                },
                                
                                {
                                    id: 'veiculos',
                                    title: 'VEÍCULOS',
                                    type: 'item',
                                    url: '/cadastros/veiculos',
                                    classes: 'nav-item',
                                    icon: 'fas fa-truck-moving'
                                },
                            ]
                        },
                        {
                            id: 'usuarios',
                            title: 'USUÁRIOS',
                            type: 'item',
                            url: '/cadastros/usuarios',
                            classes: 'nav-item',
                            icon: 'fas fa-user-tie'
                        },
                        
                        {
                            id: 'empresas',
                            title: 'EMPRESAS',
                            type: 'item',
                            url: '/cadastros/empresas',
                            classes: 'nav-item',
                            icon: 'fas fa-store'
                        },
                        {
                            id: 'contador',
                            title: 'CONTADOR',
                            type: 'item',
                            url: '/cadastros/contadores',
                            classes: 'nav-item',
                            icon: 'fas fa-calculator'
                        },
                        {
                            id: 'clientes',
                            title: 'CLIENTES',
                            type: 'item',
                            url: '/cadastros/clientes',
                            classes: 'nav-item',
                            icon: 'fas fa-users'
                        },
                        {
                            id: 'funcionarios',
                            title: 'FUNCIONÁRIOS',
                            type: 'item',
                            url: '/cadastros/funcionarios',
                            classes: 'nav-item',
                            icon: 'fa fa-id-badge'
                        },
                        {
                            id: 'certificados',
                            title: 'CERTIFICADO DIGITAL',
                            type: 'item',
                            url: '/cadastros/certificados',
                            classes: 'nav-item',
                            icon: 'fas fa-clipboard'
                        },
                    ]
                },                
                {
                    id: 'menu-parametrizacoes',
                    title: 'PARAMETRIZAÇÕES',
                    type: 'collapse',
                    icon: 'fas fa-sliders-h',
                    children: [
                        {
                            id: 'parametros',
                            title: 'PARÂMETROS',
                            type: 'item',
                            url: '/parametros',
                            classes: 'nav-item',
                            icon: 'fas fa-suitcase'
                        },
                        {
                            id: 'preferencias',
                            title: 'PREFERÊNCIAS',
                            type: 'item',
                            url: '/preferencias',
                            classes: 'nav-item',
                            icon: 'fas fa-briefcase'
                        },
                        {
                            id: 'preferencias_email',
                            title: 'PARAMÊTROS DE EMAIL',
                            type: 'item',
                            url: '/parametros_email',
                            classes: 'nav-item',
                            icon: 'fas fa-envelope-square'
                        },
                        {
                            id: 'parametros_backup',
                            title: 'PARAMÊTROS DE BACKUP',
                            type: 'item',
                            url: '/parametros_backup',
                            classes: 'nav-item',
                            icon: 'fas fa-database'
                        },
                    ]
                },
                {
                    id: 'menu-relatorios',
                    title: 'RELATÓRIOS',
                    type: 'item',
                    url: '/relatorios',
                    classes: 'nav-item',
                    icon: 'fa fa-file-pdf',
                },
                {
                    id: 'menu-backup',
                    title: 'BACKUP',
                    type: 'collapse',
                    icon: 'fas fa-cloud-upload-alt',
                    children: [
                        {
                            id: 'backup_documentos_fiscais',
                            title: 'DOCUMENTOS FISCAIS',
                            type: 'item',
                            url: '/backup_documentos_fiscais',
                            classes: 'nav-item',
                            icon: 'fas fa-database'
                        },
                        {
                            id: 'historico_documentos_fiscais',
                            title: 'HISTÓRICO',
                            type: 'item',
                            url: '/historico_backups',
                            classes: 'nav-item',
                            icon: 'fas fa-history'
                        },
                    ]
                }
                // {
                //     id: 'sample-page',
                //     title: 'Sample Page',
                //     type: 'item',
                //     url: '/sample-page',
                //     classes: 'nav-item',
                //     icon: 'feather icon-sidebar'
                // },
                // {
                //     id: 'signin-1',
                //     title: 'Sign in',
                //     type: 'item',
                //     url: '/auth/signin-1',
                //     icon: 'feather icon-lock',
                //     badge: {
                //         title: 'New',
                //         type: 'label-danger'
                //     },
                //     target: true,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'menu-level',
                //     title: 'Menu Levels',
                //     type: 'collapse',
                //     icon: 'feather icon-menu',
                //     children: [
                //         {
                //             id: 'menu-level-1.1',
                //             title: 'Menu Level 1.1',
                //             type: 'item',
                //             url: '#!',
                //         },
                //         {
                //             id: 'menu-level-1.2',
                //             title: 'Menu Level 2.2',
                //             type: 'collapse',
                //             children: [
                //                 {
                //                     id: 'menu-level-2.1',
                //                     title: 'Menu Level 2.1',
                //                     type: 'item',
                //                     url: '#',
                //                 },
                //                 {
                //                     id: 'menu-level-2.2',
                //                     title: 'Menu Level 2.2',
                //                     type: 'collapse',
                //                     children: [
                //                         {
                //                             id: 'menu-level-3.1',
                //                             title: 'Menu Level 3.1',
                //                             type: 'item',
                //                             url: '#',
                //                         },
                //                         {
                //                             id: 'menu-level-3.2',
                //                             title: 'Menu Level 3.2',
                //                             type: 'item',
                //                             url: '#',
                //                         }
                //                     ]
                //                 }
                //             ]
                //         }
                //     ]
                // }
            ]
        }
    ]
}