import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const HomePage = React.lazy(() => import('./App/views/Home/Home'));
const MarcaPage = React.lazy(() => import('./App/views/cadastros/Marca/MarcaView'));
const EmpresaPage = React.lazy(() => import('./App/views/Admin/cadastros/Empresa/EmpresaView'));
const EmpresaFormPage = React.lazy(() => import('./App/views/Admin/cadastros/Empresa/EmpresaForm'));

const routes = [
    { path: '/home', exact: true, name: 'Home', component: HomePage },
    { path: '/cadastros/marcas', exact: true, name: 'Marcas', component: MarcaPage },
    { path: '/cadastros/empresas', exact: true, name: 'Empresas', component: EmpresaPage },
    { path: '/cadastros/empresas/:codigo', exact: false, name: 'Empresa Form', component: EmpresaFormPage },
];

export default routes;