export default {
  items: [
      {
          id: 'pages',
          title: 'Pages',
          type: 'group',
          icon: 'icon-pages',
          children: [
              {
                  id: 'menu-level',
                  title: 'CADASTROS',
                  type: 'collapse',
                  icon: 'fas fa-file-alt',
                  children: [
                      {
                          id: 'empresas',
                          title: 'EMPRESAS',
                          type: 'item',
                          url: '/cadastros/empresas',
                          classes: 'nav-item',
                          icon: 'fas fa-store'
                      },
                  ]
              },                
              
          ]
      }
  ]
}