import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const MarcaPage = React.lazy(() => import('./App/views/cadastros/Marca/MarcaView'));
const DepartamentoPage = React.lazy(() => import('./App/views/cadastros/Departamento/DepartamentoView'));
const UnidadeMedidaPage = React.lazy(() => import('./App/views/cadastros/UnidadeMedida/UnidadeMedidaView'));
const SubdepartamentoPage = React.lazy(() => import('./App/views/cadastros/Subdepartamento/SubdepartamentoView'));
const UsuarioPage = React.lazy(() => import('./App/views/cadastros/Usuario/UsuarioView'));
const EmpresaPage = React.lazy(() => import('./App/views/cadastros/Empresa/EmpresaView'));
const ProdutoPage = React.lazy(() => import('./App/views/cadastros/Produto/ProdutoView'));
const ProdutoFormPage = React.lazy(() => import('./App/views/cadastros/Produto/ProdutoForm'));
const TabelaPreco = React.lazy(() => import('./App/views/cadastros/TabelaPreco/TabelaPrecoView'));
const TributacaoPorNcmPage = React.lazy(() => import('./App/views/cadastros/TributacaoPorNCM/TributacaoPorNCMView'));
const TributacaoPorNcmFormPage = React.lazy(() => import('./App/views/cadastros/TributacaoPorNCM/TributacaoPorNCMForm'));
const TributacaoPorProdutoPage = React.lazy(() => import('./App/views/cadastros/TributacaoPorProduto/TributacaoPorProdutoView'));
const TributacaoPorNcmProdutoPage = React.lazy(() => import('./App/views/cadastros/TributacaoPorProduto/TributacaoPorProdutoForm'));
const ClienteFornecedorPage = React.lazy(() => import('./App/views/cadastros/ClienteFornecedor/ClienteFornecedorView'));
const ClienteFornecedorFormPage = React.lazy(() => import('./App/views/cadastros/ClienteFornecedor/ClienteFornecedorForm'));
const PreferenciaFormPage = React.lazy(() => import('./App/views/Parametrizacoes/Preferencias/PreferenciaForm'));
const ParametroFormPage = React.lazy(() => import('./App/views/Parametrizacoes/Parametros/ParametroForm'));
const MeuPerfilFormPage = React.lazy(() => import('./App/views/MeuPerfil/MeuPerfilForm'));
const NFCePage = React.lazy(() => import('./App/views/NFCe/NFCeView'));
const NotaFiscalEntradaView = React.lazy(() => import('./App/views/NotaFiscalEntrada/NotaFiscalEntradaView'));
const NotaFiscalEntradaForm = React.lazy(() => import('./App/views/NotaFiscalEntrada/NotaFiscalEntradaForm'));
const AuxiliarVendaPage = React.lazy(() => import('./App/views/GerenciarAuxiliar/AuxiliarVendaView'));
const NFePage = React.lazy(() => import('./App/views/GerenciarNFe/NFeView'));
const NFeFormPage = React.lazy(() => import('./App/views/NFe/NFeForm'));
const PDVFormPage = React.lazy(() => import('./App/views/PDV/PDVForm'));
const FuncionarioPage = React.lazy(() => import('./App/views/cadastros/Funcionario/FuncionarioView'));
const FuncionarioFormPage = React.lazy(() => import('./App/views/cadastros/Funcionario/FuncionarioForm'));
const CertificadoDigitalPage = React.lazy(() => import('./App/views/cadastros/CertificadoDigital/CertificadoDigitalView'));
const NFCeCargaView = React.lazy(() => import('./App/views/NFCeCarga/NFCeCargaView'));
const TransportadoraPage = React.lazy(() => import('./App/views/cadastros/Transportadoras/TransportadoraView'));
const TransportadoraFormPage = React.lazy(() => import('./App/views/cadastros/Transportadoras/TransportadoraForm'));
const EspecieVolumePage = React.lazy(() => import('./App/views/cadastros/EspecieVolume/EspecieVolumeView'));
const VeiculoPage = React.lazy(() => import('./App/views/cadastros/Veiculo/VeiculoView'));
const ContadorPage = React.lazy(() => import('./App/views/cadastros/Contador/ContadorView'));
const ContadorFormPage = React.lazy(() => import('./App/views/cadastros/Contador/ContadorForm'));
const HomePage = React.lazy(() => import('./App/views/Home/Home'));
const PreferenciaEmailFormPage = React.lazy(() => import('./App/views/Parametrizacoes/PreferenciasEmail/PreferenciasEmailForm'));
const ParametroBackupFormPage = React.lazy(() => import('./App/views/Parametrizacoes/ParametroBackup/ParametroBackupForm'));
const BackupDocumentosFiscaisPage = React.lazy(() => import('./App/components/Modals/BackupDocumentosFiscaisModal.js'));
const HistoricoBackupPage = React.lazy(() => import('./App/views/Backup/HistoricoBackupView.js'));
const RelatorioPage = React.lazy(() => import('./App/views/Relatorios/RelatoriosView'));

const routes = [
    { path: '/home', exact: true, name: 'Home', component: HomePage },

    { path: '/cadastros/marcas', exact: true, name: 'Marcas', component: MarcaPage },
    { path: '/cadastros/departamentos', exact: true, name: 'Departamentos', component: DepartamentoPage },
    { path: '/cadastros/unidades_medida', exact: true, name: 'Unidades de medida', component: UnidadeMedidaPage },
    { path: '/cadastros/subdepartamentos', exact: true, name: 'Subdepartamentos', component: SubdepartamentoPage },
    { path: '/cadastros/usuarios', exact: true, name: 'Usuários', component: UsuarioPage },
    { path: '/cadastros/empresas', exact: true, name: 'Empresas', component: EmpresaPage },
    { path: '/cadastros/produtos', exact: true, name: 'Produtos', component: ProdutoPage },
    { path: '/cadastros/produtos/:codigo', exact: false, name: 'Produtos Form', component: ProdutoFormPage },
    { path: '/cadastros/tabela_preco', exact: true, name: 'Tabela de Preços', component: TabelaPreco },
    { path: '/cadastros/tributacao_por_ncm', exact: true, name: 'Tributação por NCM', component: TributacaoPorNcmPage },
    { path: '/cadastros/tributacao_por_ncm/:codigo', exact: true, name: 'Tributação por NCM Form', component: TributacaoPorNcmFormPage },
    { path: '/cadastros/tributacao_por_produto', exact: true, name: 'Tributação por Produto', component: TributacaoPorProdutoPage },
    { path: '/cadastros/tributacao_por_produto/:codigo', exact: true, name: 'Tributação por Produto Form', component: TributacaoPorNcmProdutoPage },
    { path: '/cadastros/clientes', exact: true, name: 'Clientes', component: ClienteFornecedorPage },
    { path: '/cadastros/clientes/:codigo', exact: false, name: 'Clientes Form', component: ClienteFornecedorFormPage },
    { path: '/cadastros/funcionarios', exact: true, name: 'Funcionários ', component: FuncionarioPage },
    { path: '/cadastros/funcionarios/:codigo', exact: false, name: 'Funcionario Form', component: FuncionarioFormPage },
    { path: '/cadastros/certificados', exact: true, name: 'Certificado Digital ', component: CertificadoDigitalPage },
    { path: '/cadastros/transportadoras', exact: true, name: 'Transportadoras ', component: TransportadoraPage },
    { path: '/cadastros/transportadoras/:codigo', exact: false, name: 'Transportadoras Form', component: TransportadoraFormPage },
    { path: '/cadastros/unidades_volume', exact: true, name: 'Unidades de Volume', component: EspecieVolumePage },
    { path: '/cadastros/veiculos', exact: true, name: 'Veículos', component: VeiculoPage },
    { path: '/cadastros/contadores', exact: true, name: 'Contador', component: ContadorPage },
    { path: '/cadastros/contadores/:codigo', exact: false, name: 'Contador Form', component: ContadorFormPage },
    
    { path: '/preferencias', exact: true, name: 'Preferencias Form', component: PreferenciaFormPage },
    { path: '/parametros_email', exact: true, name: 'Preferencias Email Form', component: PreferenciaEmailFormPage },
    { path: '/parametros_backup', exact: true, name: 'Paramêtros de backup', component: ParametroBackupFormPage },
    { path: '/parametros', exact: true, name: 'Parâmetros Form', component: ParametroFormPage },
    { path: '/meu_perfil', exact: true, name: 'Meu perfil Form', component: MeuPerfilFormPage },
    
    { path: '/auxiliar-venda', exact: true, name: 'Gerenciar Auxiliar de Venda', component: AuxiliarVendaPage },
    { path: '/nfce', exact: true, name: 'Gerenciar NFCe', component: NFCePage },
    { path: '/pdv/:codigo', exact: true, name: 'NFCe', component: PDVFormPage },
    { path: '/nfe', exact: true, name: 'Gerenciar NFe', component: NFePage },
    { path: '/nfe/:codigo', exact: true, name: 'NFe', component: NFeFormPage },
    { path: '/carga', exact: true, name: 'NFCe Teste Carga', component: NFCeCargaView },
    { path: '/nota_entrada', exact: true, name: 'Gerenciar Notas Fiscais de Entrada', component: NotaFiscalEntradaView },
    { path: '/nota_entrada/:codigo', exact: true, name: 'Nota Fiscal de Entrada', component: NotaFiscalEntradaForm },
    { path: '/backup_documentos_fiscais', exact: true, name: 'Backup Documentos Fiscais', component: BackupDocumentosFiscaisPage },
    { path: '/historico_backups', exact: true, name: 'Histórico de Backup Documentos Fiscais', component: HistoricoBackupPage },
    
    { path: '/relatorios', exact: true, name: 'Relatórios', component: RelatorioPage },
];

export default routes;

