import * as actionTypes from 'src/store/actions/Auth/auth.actions';
import jwtService  from 'src/App/services/jwtService.js';

const initialState = {
    usuarioAutenticado: false,
    adminAutenticado: false,
    usuario: '',
    empresaLogada: '',
    payload: undefined
};

const reducer = (state = initialState, action) => {
  let usuarioLogado = jwtService.getUsuarioLogado();
  if(usuarioLogado) {
    state.usuario = usuarioLogado;
    state.empresaLogada = usuarioLogado.empresaLogada;
    if(!usuarioLogado.adminLogin) {
      state.adminAutenticado = false;
      state.usuarioAutenticado = true;
    } else {
      state.adminAutenticado = true;
      state.usuarioAutenticado = false;
    }
  }

  switch (action.type) {
      case actionTypes.USUARIO_AUTENTICADO:
          return {
              ...state,
              usuarioAutenticado: action.usuarioAutenticado,
              payload: action.payload
          };
      case actionTypes.ADMIN_AUTENTICADO:
          return {
              ...state,
              adminAutenticado: action.adminAutenticado,
              payload: action.payload
          };
      case actionTypes.SET_USER_DATA:
          {
              return {
                  ...state,
                  usuario: action.payload
              };
          }
      case actionTypes.SET_EMPRESA_LOGADA:
          {
              return {
                  ...state,
                  empresaLogada: action.payload
              };
          }
      default:
          return state;
  }
};

export default reducer;